/* Navigation */

.nav {
	background: #1D1D1D;
	font-family: 'Manrope', sans-serif;
	height: 80px;
	top: 0;
	width: 100%;
	display: flex;
	font-weight: 700;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: fixed;
	flex-direction: row;
	top: 0;
	margin: 0;
	z-index: 999;
	opacity: 0.95 !important;
  }


  
.navbar-container {
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	height: 80px;
	width: auto;
}


  .nav-menu {
	display: flex;
	height: 0;
	width: 100%;
	overflow: hidden;
	list-style-type: none;
	font-weight: bold;
	background: #1d1d1d;
	
  }

  .menu-icon  {
	cursor: pointer;
	margin-right: 20px;
	z-index: 999;
  }

  .show-nav {
	margin-top: 480px;
    height: auto;
	width: 100vw;
	position: absolute;
	display: flex;
	flex-direction: column;
}

  
  .navbar-logo {
	display: block;
    font-family: "Edge Of Madness";
	color: #D52228;
	cursor: default;
	word-break:keep-all;
	font-weight: 900;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1.2em;
	display: flex;
	margin-right: auto;
	margin-left: 1rem;
	-webkit-text-stroke-width: 0.05px;
    -webkit-text-stroke-color: white;
  }
  
  .fa-tree {
	margin-left: 1.0rem;
	font-size: 1.8rem;
  }
  
  .nav-item {
	height: 100px;
	color: #303956;
  }
  
  .nav-links {
	font-family: "Barlow";
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	padding: 0rem 1.2rem;
	height: 100%;
	overflow:hidden;
	list-style-type: none;
  }

  .nav-links:hover {
     color: var(--secondary-color);
	border-bottom: 2px solid var(--secondary-color);
	transition: all 1s ease-out;
	cursor: pointer;
  }
  
  .qbtn{
	display: none;
  }

  .quote-div{
	padding-left: 5%;
  }


 
@media screen and (min-width: 1200px) {

	.nav {
		background: #1D1D1D;
		font-family: 'Manrope', sans-serif;
		height: 80px;
		width: 100%;
		display: flex;
		font-weight: 700;
		text-transform: uppercase;
		justify-content: center;
		align-items: center;
		font-size: 1rem;
		position: fixed;
		flex-direction: row;
		top: 0;
		margin: 0;
		z-index: 999;
		opacity: 0.95 !important;
	}


	
	.menu-icon {
		display: none;
		cursor: pointer;
	}
	.navbar-container {
		display: flex;
		position: absolute;
		justify-content: center;
		align-items: center;
		height: 80px;
		width: auto;
	  }

	.navbar-logo{
		margin-left: 2rem;
		font-size: 1.5rem;
	}
	.nav-menu {
		height: 80px;
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.nav-links {
		font-family: "Barlow";
		color: #fff;
		display: flex;
		align-items: center;
		text-decoration: none;
		padding: 0rem 1.2rem;
		height: 100%;
		font-size: 1.2rem;
	  }
	.qbtn {
		display: inline-block;
		font:normal normal 300 1.1em 'Barlow';
		font-weight: 700;
		text-decoration:none;	
		color:#fff;
		background-color: var(--secondary-color);
		border: 1.5px solid #fff;
		border-radius: 10px;
		padding: .6rem 1rem;
		margin-right: 5%;
		background-size: 200% 100%;	
		background-image: linear-gradient(to right, transparent 50%, var(--main-color) 50%);
		transition: background-position 1.5s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color 2s ease;
	}
	
	
	  .qbtn:hover {
		color:rgba(255, 255, 255, 1);
		background-color:var(--main-color);
		background-position: -100% 100%;
		border: 2px solid #fff;
		cursor: pointer;
	  }
}

