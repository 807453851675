body {
    margin: 0;
    padding: 0;
   }
   .main {
    max-height: 550px;;
    background-color: #303956;
    color: white;
    font-size: 2rem;
    text-align: center;
    line-height: 550px;
   }
   footer{
    position: relative;
    bottom: 0;
   }
   .footer-distributed{
    border-top: 1px solid white;
    background-color: #1D1D1D;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    
    padding: 55px 50px;
    margin-top: 80px;
   }
   .footer-paragraph-subtitle{
       color: #fff;
       font-size: 1.2rem;
   }
    
   .footer-distributed .footer-left,
   .footer-distributed .footer-center,
   .footer-distributed .footer-right{
    display: inline-block;
    vertical-align: top;
   }
    
   .footer-distributed .footer-left{
    width: 40%;
   }
    
   .footer-distributed h1{
    font-family: "Edge Of Madness";
	color: #D52228;
	letter-spacing: 3px;
	cursor: default;
	font-weight: 900;
	margin-left: 0;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1.8em;
	display: flex;
	margin-right: auto;
   }
 
    
    
   .footer-distributed .footer-links{
    color:  #ffffff;
    margin: 20px 0 12px;
    padding: 0;
   }
    
   .footer-distributed .footer-links a{
    display:inline-block;
    line-height: 1.8;
    text-decoration: none;
    color:  inherit;
   }
    
   .footer-distributed .footer-company-name{
    color:  #fff;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 0;
   }
    
    
   .footer-distributed .footer-center{
    width: 25%;
   }
    
   .footer-distributed .footer-center i{
    background-color:  #33383b;
    color: #ffffff;
    font-size: 30px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
   }
    
   .footer-distributed .footer-center i.fa-envelope{
    font-size: 17px;
    line-height: 38px;
   }
    
   .footer-distributed .footer-center p{
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin:0;
    font-size: 1.2rem;
   }
    
   .footer-distributed .footer-center p span{
    display:block;
    font-weight: normal;
    font-size: 1.2rem;
    line-height:2;
   }
    
   .footer-distributed .footer-center p a{
    color:  #5383d3;
    text-decoration: none;
    font-size: 1rem;
   }
    
   .footer-distributed .footer-right{
    width: 30%;
   }
    
   .footer-distributed .footer-company-about{
    line-height: 20px;
    color:  #fff;
    font-size: 1.1rem;
    font-weight: lighter;
    font-family: 'Barlow', Courier, monospace;
    margin: 0;
   }
    
   .footer-distributed .footer-company-about span{
    display: block;
    color: #D52228;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
   }
    
   .footer-distributed .footer-icons{
    margin-top: 25px;
   }
    
   .footer-distributed .footer-icons a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color:  #33383b;
    border-radius: 2px;
    
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    
    margin-right: 3px;
    margin-bottom: 5px;
   }
    
    
   @media (max-width: 880px) {
    
    .footer-distributed{
    font: bold 14px sans-serif;
    
    }
    
    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    }
    
    .footer-distributed .footer-center i{
    margin-left: 0;
    }
    .main {
    line-height: normal;
    font-size: auto;
    }
    
   }


@media (max-width: 575.98px) {
    .footer-distributed h1{
        letter-spacing: 3px;
        cursor: default;
        font-weight: 900;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.5em;
        display: flex;
        margin-left: 25%;
       }
}