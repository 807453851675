@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

@font-face {
	font-family: 'Edge Of Madness';
	src: url('../fonts/EdgeOfMadness.ttf');
}
@font-face {
	font-family: 'Mellinda Lopez';
	src: url('/src/fonts/MellindaLopez.ttf');
}
@font-face {
	font-family: 'University';
	src: url('/src/fonts/University.otf');
}
:root {
    --main-color: #303956;
    --secondary-color: #c30000;
}

body, html {
    font-family: "Bebas Neue", sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialised !important;
    color: var(--main-color);
    font-weight: 400;
	max-width: 100vw;
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box;
    font-smooth: always;
    -moz-osx-font-smoothing: grayscale;
}

h2, h3,h4 {
    font-family: "Bebas Neue", sans-serif;
}

li {
    margin-bottom: 5px;
}

/* Add white bg as user scrolls down the page*/
/* Navigation */

