*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Lato", sans-serif;
    background-color: #303956;
}

h1{
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    margin: 10%;
}
h3 {
    color: #fff;
}


.coming-soon {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.maintenance-title {
    margin-bottom: 2%;
}

.countdown {
    height: 20vh;
    display: flex;
    justify-content: space-around;
    background-color: #303956;
}

.day, .hour, .minute,.second{
    font-size: 3rem;
    color: #fff;
}

.waiting{
    height: 50vh;
}

.contact {
    font-size: 2rem;
    margin-top: 5%;
}


/* Different devices */



@media (max-width: 1399.98px) {  }


@media (max-width: 1199.98px) {  }


@media screen and (max-width: 960px) {
    .coming-soon {
        height: 80vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .maintenance-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

	h1{
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: 10%;
    }
}


@media(max-width: 767.98px) {
    .coming-soon {
        height: 80vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .maintenance-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 3%;
    }
    .waiting{
        height: 35vh;
    }

	h1{
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: 10%;
    }
}  

@media (max-width: 575.98px){

    .coming-soon {
        height: 80vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .maintenance-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 10%;
    }

    .waiting{
        height: 35vh;
    }

	h1{
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: 10%;
    }
}