@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family: 'Edge Of Madness';
	src: url(/static/media/EdgeOfMadness.0bd2af4f.ttf);
}
@font-face {
	font-family: 'Mellinda Lopez';
	src: url('/src/fonts/MellindaLopez.ttf');
}
@font-face {
	font-family: 'University';
	src: url('/src/fonts/University.otf');
}
:root {
    --main-color: #303956;
    --secondary-color: #c30000;
}

body, html {
    font-family: "Bebas Neue", sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialised !important;
    color: #303956;
    color: var(--main-color);
    font-weight: 400;
	max-width: 100vw;
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box;
    font-smooth: always;
    -moz-osx-font-smoothing: grayscale;
}

h2, h3,h4 {
    font-family: "Bebas Neue", sans-serif;
}

li {
    margin-bottom: 5px;
}

/* Add white bg as user scrolls down the page*/
/* Navigation */


@font-face{
	font-family: "Edge Of Madness";
	src: url(/static/media/EdgeOfMadness.0bd2af4f.ttf);
}


.title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    padding: 20px;
    padding-top: 10vh;
    margin-bottom: -10vh;
}

.split {
    height: 100vh;
    width: 100vw;
    position: relative;
    flex-direction: row;
    z-index: 0;
    flex-wrap: wrap;
}

.home-left {
    flex: 50% 1;
    height: 100%;
    margin-left: 10vw;
    padding-top: 10vh;
}

img {
    height: 70vh;
}


.home-right{
    flex: 50% 1;
    right: 0;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
    
.offer {
    display: flex;
    color: #fff;
    padding-bottom: 20px;
}

.home-centered {
    position: absolute;
}

.home-campaign-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: normal 900 3em 'Barlow';
    max-height: 10vh;
    padding-top: 20vh;
    margin-bottom: 10vh;
    display: flex;
    color: rgba(213,34,40,1);
}

.home-campaign{
    height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.list p{
    color: #656364;
    font-size: 1.1rem;
    text-transform: uppercase;
}

.home-get-in-touch-button {
    background-color: rgba(213,34,40,1);
    margin-top: 10px;
    border-radius: 10px;
    font: normal 300 1.3em 'Barlow';
}

.mobilequote-div {

}


@media screen and (max-width: 1600px) {
    .home-right  {
        flex:30% 1;
    }
}

@media screen and (max-width: 1000px) {
    .getquote-div {
        display: none;
    }
}



@media(max-width: 900px) {
    .home-campaign{
        height: 90vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: -1;
    }
    .home-right, .home-left {
        flex: 100% 1;
    }
  
}


.carousel-container{
    display: flex;
    height: 100vh;
    width: 100vw;
    padding-top: 10vh;
    background-image: url("https://res.cloudinary.com/dnho57ne8/image/upload/v1668689621/product-showcase-background-warehouse-blurred-3d-rendering-picture-id876420708_es5kfu.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.carousel-split {
    display: flex;
    flex: 100% 1;
    flex-direction: row;
    justify-content: center;
    padding-top: 10vh;
}

.carousel-left{
    flex: 60% 1;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    padding-left: 10%;
}

.carousel-right {
    flex: 50% 1;
}

.carousel-right img {
    height: 70%;
}

.carousel-title {
    color: #fff;
    font-size: 5rem;
    padding-bottom: 3vh;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: rgba(213,34,40,1);
    text-transform: uppercase;
    word-break: keep-all;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.carousel-description {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
}
.carousel-description2 {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
}

.carousel-left .qbtn {
    display: none;
}

@media screen and (max-width: 1700px){
    .carousel-right img{
        height: 60%;
    }
    .carousel-left{
        flex: 70% 1;
    }
    .carousel-title{
        font-size: 4rem;
    }
    .carousel-left .qbtn {
        display: none;
    }
}

@media screen and (max-width:1000px) {
    .carousel-split{
        flex-direction: column;
        align-items: center;
    }
    .carousel-left{
        flex: 70% 1;
        margin-right:10%;
    }
    .carousel-title{
        font-size: 3.5rem;
        margin-bottom: 0;
    }
  
    .carousel-right{
        flex: 0 1;
        display: none;
        justify-content: center;
        align-items: center;
    }
    .carousel-right img{
        height: 400px;
    }
    .carousel-left .qbtn {
    display: inline-block;
    font: normal normal 300 1.1em 'Barlow';
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    background-color: var(--secondary-color);
    border: 1.5px solid #fff;
    border-radius: 10px;
    padding-top: 10vh;
    padding: 0.6rem 1rem;
    margin-right: 5%;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, var(--main-color) 50%);
    }

}

@media screen and (max-width: 900px){
    .carousel-left .contact1-form-btn {
        margin-top: 10px;
    }
}

.body-div {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    position:relative;
    width: 100%;
    background-color: #fff;
}

  
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  .background {
    display: flex;
    min-height: 100vh;
  }
  
  .container {
    flex: 1 1 800px;
    width: 900px;
    margin: auto;
    padding: 10px;
  }
  
  .screen {
    position: relative;
    background: #3e3e3e;
    border-radius: 15px;
  }
  
  .screen:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
    z-index: -1;
  }
  
  .screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background:  rgba(0, 0, 0, .4);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .screen-header-left {
    margin-right: auto;
    flex-direction: row-reverse;
  }
  
  .screen-header-button {
    display: inline-flex;
    flex-direction: row-reverse;
    width: 10px;
    height: 10px;
    margin-right: 3px;
    border-radius: 8px;
    background: white;
  }
  
  .screen-header-button.close {
    background: #ed1c6f;
    padding: 0 !important;
    margin-top: 6px !important;
  }
  
  .screen-header-button.maximize {
    background: #74c54f;
  }
  
  .screen-header-button.minimize {
    background: #e8e925;
  }
  
  .screen-header-right {
    display: flex;
    color: white;
  }

  .screen-header-right img {
    color: #fff;
    border-color: white;
    height: 20px;
    width: 15px;
  }
  
  
  .screen-body {
    display: flex;
    background-image: url("https://res.cloudinary.com/dnho57ne8/image/upload/v1668443175/iu_nkd2pb.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .screen-body-item {
    width: 30vw;
    flex: 1 1;
    padding: 60px;
  }
  
  .screen-body-item.left {
    display: flex;
    width: 50%;
    height: auto;
    left: 0;
    background: transparent;
    flex-direction: column;
  }
  
  .app-title {
    display: flex;
    flex-direction: column;
    position: relative;
    color: red;
    font-size: 26px;
  }
  
  .app-title:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 30px;
    width: 30px;
    height: 4px;
    background: #ea1d6f;
  }
  
  .app-contact {
    padding: 5px;
    margin-top: auto;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .app-form-group {
    margin-bottom: 15px;
  }
  
  .app-form-group.message {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
  }
  
  .app-form-control {
    width: 100%;
    padding: 0px;
    background: none;
    border: none;
    border-bottom: 1px solid rgb(256,256,256);
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    transition: border-color .2s;
  }
  
  .app-form-control::-webkit-input-placeholder {
    color: #fff;
  }
  
  .app-form-control::placeholder {
    color: #fff;
  }
  
  .app-form-control:focus {
    border-bottom-color: rgb(0, 0, 0);
  }
  
  .app-form-button {
    background: none;
    border: none;
    color: #ea1d6f;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    font-weight: 700;
    margin-right: 10px;
  }
  
  .app-form-button:hover {
    color: #b9134f;
  }
  
 
   
  
  @media screen and (max-width: 725px) {
    .container {
      flex: 0 1 600px;
      width: 1000px;
      margin: auto;
      padding: 10px;
    }
    .screen-body-item {
      flex: 1 1;
      padding: 50px;
      background-color: #fff;
      border-radius: 0px 0px 15px 15px;
    }
    
    

    
    .screen {
      position: relative;
      background: #3e3e3e;
      border-radius: 15px;
    }
    
    .screen:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      bottom: 0;
      border-radius: 15px;
      box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
      z-index: -1;
    }


    .screen-body {
      flex-direction: column;
    }
  
    .screen-body-item.left {
      margin-bottom: 30px;
    }
  
    .app-title {
      flex-direction: row;
    }
  
    .app-title span {
      margin-right: 12px;
    }
  
    .app-title:after {
      display: none;
    }
  }
  
  @media screen and (max-width: 600px) {
    .container {
      flex: 0 1 500px;
      width: 1000px;
      margin: auto;
      padding: 10px;
    }
    .screen-body-item .left {
      flex: 1 1;
      background-color: #fff;
      border-radius: 0px;
    }
    
    

    
    .screen {
      position: relative;
      background: #3e3e3e;
      border-radius: 15px;
    }
    
    .screen:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      bottom: 0;
      border-radius: 15px;
      box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
      z-index: -1;
    }
  }
  
/* Navigation */

.nav {
	background: #1D1D1D;
	font-family: 'Manrope', sans-serif;
	height: 80px;
	top: 0;
	width: 100%;
	display: flex;
	font-weight: 700;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: fixed;
	flex-direction: row;
	top: 0;
	margin: 0;
	z-index: 999;
	opacity: 0.95 !important;
  }


  
.navbar-container {
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	height: 80px;
	width: auto;
}


  .nav-menu {
	display: flex;
	height: 0;
	width: 100%;
	overflow: hidden;
	list-style-type: none;
	font-weight: bold;
	background: #1d1d1d;
	
  }

  .menu-icon  {
	cursor: pointer;
	margin-right: 20px;
	z-index: 999;
  }

  .show-nav {
	margin-top: 480px;
    height: auto;
	width: 100vw;
	position: absolute;
	display: flex;
	flex-direction: column;
}

  
  .navbar-logo {
	display: block;
    font-family: "Edge Of Madness";
	color: #D52228;
	cursor: default;
	word-break:keep-all;
	font-weight: 900;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1.2em;
	display: flex;
	margin-right: auto;
	margin-left: 1rem;
	-webkit-text-stroke-width: 0.05px;
    -webkit-text-stroke-color: white;
  }
  
  .fa-tree {
	margin-left: 1.0rem;
	font-size: 1.8rem;
  }
  
  .nav-item {
	height: 100px;
	color: #303956;
  }
  
  .nav-links {
	font-family: "Barlow";
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	padding: 0rem 1.2rem;
	height: 100%;
	overflow:hidden;
	list-style-type: none;
  }

  .nav-links:hover {
     color: var(--secondary-color);
	border-bottom: 2px solid var(--secondary-color);
	transition: all 1s ease-out;
	cursor: pointer;
  }
  
  .qbtn{
	display: none;
  }

  .quote-div{
	padding-left: 5%;
  }


 
@media screen and (min-width: 1200px) {

	.nav {
		background: #1D1D1D;
		font-family: 'Manrope', sans-serif;
		height: 80px;
		width: 100%;
		display: flex;
		font-weight: 700;
		text-transform: uppercase;
		justify-content: center;
		align-items: center;
		font-size: 1rem;
		position: fixed;
		flex-direction: row;
		top: 0;
		margin: 0;
		z-index: 999;
		opacity: 0.95 !important;
	}


	
	.menu-icon {
		display: none;
		cursor: pointer;
	}
	.navbar-container {
		display: flex;
		position: absolute;
		justify-content: center;
		align-items: center;
		height: 80px;
		width: auto;
	  }

	.navbar-logo{
		margin-left: 2rem;
		font-size: 1.5rem;
	}
	.nav-menu {
		height: 80px;
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.nav-links {
		font-family: "Barlow";
		color: #fff;
		display: flex;
		align-items: center;
		text-decoration: none;
		padding: 0rem 1.2rem;
		height: 100%;
		font-size: 1.2rem;
	  }
	.qbtn {
		display: inline-block;
		font:normal normal 300 1.1em 'Barlow';
		font-weight: 700;
		text-decoration:none;	
		color:#fff;
		background-color: var(--secondary-color);
		border: 1.5px solid #fff;
		border-radius: 10px;
		padding: .6rem 1rem;
		margin-right: 5%;
		background-size: 200% 100%;	
		background-image: linear-gradient(to right, transparent 50%, var(--main-color) 50%);
		transition: background-position 1.5s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color 2s ease;
	}
	
	
	  .qbtn:hover {
		color:rgba(255, 255, 255, 1);
		background-color:var(--main-color);
		background-position: -100% 100%;
		border: 2px solid #fff;
		cursor: pointer;
	  }
}


body {
    margin: 0;
    padding: 0;
   }
   .main {
    max-height: 550px;;
    background-color: #303956;
    color: white;
    font-size: 2rem;
    text-align: center;
    line-height: 550px;
   }
   footer{
    position: relative;
    bottom: 0;
   }
   .footer-distributed{
    border-top: 1px solid white;
    background-color: #1D1D1D;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    
    padding: 55px 50px;
    margin-top: 80px;
   }
   .footer-paragraph-subtitle{
       color: #fff;
       font-size: 1.2rem;
   }
    
   .footer-distributed .footer-left,
   .footer-distributed .footer-center,
   .footer-distributed .footer-right{
    display: inline-block;
    vertical-align: top;
   }
    
   .footer-distributed .footer-left{
    width: 40%;
   }
    
   .footer-distributed h1{
    font-family: "Edge Of Madness";
	color: #D52228;
	letter-spacing: 3px;
	cursor: default;
	font-weight: 900;
	margin-left: 0;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1.8em;
	display: flex;
	margin-right: auto;
   }
 
    
    
   .footer-distributed .footer-links{
    color:  #ffffff;
    margin: 20px 0 12px;
    padding: 0;
   }
    
   .footer-distributed .footer-links a{
    display:inline-block;
    line-height: 1.8;
    text-decoration: none;
    color:  inherit;
   }
    
   .footer-distributed .footer-company-name{
    color:  #fff;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 0;
   }
    
    
   .footer-distributed .footer-center{
    width: 25%;
   }
    
   .footer-distributed .footer-center i{
    background-color:  #33383b;
    color: #ffffff;
    font-size: 30px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
   }
    
   .footer-distributed .footer-center i.fa-envelope{
    font-size: 17px;
    line-height: 38px;
   }
    
   .footer-distributed .footer-center p{
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin:0;
    font-size: 1.2rem;
   }
    
   .footer-distributed .footer-center p span{
    display:block;
    font-weight: normal;
    font-size: 1.2rem;
    line-height:2;
   }
    
   .footer-distributed .footer-center p a{
    color:  #5383d3;
    text-decoration: none;
    font-size: 1rem;
   }
    
   .footer-distributed .footer-right{
    width: 30%;
   }
    
   .footer-distributed .footer-company-about{
    line-height: 20px;
    color:  #fff;
    font-size: 1.1rem;
    font-weight: lighter;
    font-family: 'Barlow', Courier, monospace;
    margin: 0;
   }
    
   .footer-distributed .footer-company-about span{
    display: block;
    color: #D52228;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
   }
    
   .footer-distributed .footer-icons{
    margin-top: 25px;
   }
    
   .footer-distributed .footer-icons a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color:  #33383b;
    border-radius: 2px;
    
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    
    margin-right: 3px;
    margin-bottom: 5px;
   }
    
    
   @media (max-width: 880px) {
    
    .footer-distributed{
    font: bold 14px sans-serif;
    
    }
    
    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    }
    
    .footer-distributed .footer-center i{
    margin-left: 0;
    }
    .main {
    line-height: normal;
    font-size: auto;
    }
    
   }


@media (max-width: 575.98px) {
    .footer-distributed h1{
        letter-spacing: 3px;
        cursor: default;
        font-weight: 900;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.5em;
        display: flex;
        margin-left: 25%;
       }
}
  .get-quote{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 90vw;
    max-width: 91vw;
    position: absolute;
    padding-left: 10%;
    bottom: 1vh;
    z-index: 999;
    border-radius: 50px;
    opacity: 0.95 !important;
    
  }

  .quote-title h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin: 0;
      font-size: 2em; 
      padding-top: 20px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      background-color: #1D1D1D;  
      opacity: 0.95;
  }

  .form-inline {
    padding-left: 50px;
    padding-right: 50px;
    min-height: 15vh;
    max-height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #1D1D1D;
    opacity: 0.95;
  }
  
  .form-inline input {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin: 5px 50px 5px 0px;
    padding: 15px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .form-inline button {
    padding: 10px 40px;
    background-color: #c30000;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    font: normal 300 1.4em 'Barlow';
  }
 
  .form-inline select{
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin: 5px 10px 5px 0px;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }


  .validate-input{
    padding-right: 10px;
  }

  .cancel{
    display: none;
    visibility: gone;
  }
  
  @media screen and (max-width: 1200px) {

   

    .form-inline {
      min-height: 15vh;
      max-height: 50vh;
      min-width: 100%;
      display: grid;
      grid-template-columns: repeat(3,auto);
      align-items: center;
      justify-content: center;
    }
    .validate-input {
      padding: 5px;
    }
    
    
    .form-inline input {
      display: flex;
      align-items: center;
      vertical-align: middle;
      margin: 5px 5px 5px 0px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
    
    .form-inline button {
      padding: 10px 90px;
      background-color: #c30000;
      border: 1px solid #ddd;
      color: white;
      cursor: pointer;
      border-radius: 10px;
    }
   
    .form-inline select{
      display: flex;
      align-items: center;
      vertical-align: middle;
      margin: 5px 10px 5px 5px;
      padding: 15px;
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
    }

  
    input.input-box {
      height: 50px;
      border-radius: 10px;
      padding: 0 50px;
    }
   
  }
  

  
  @media screen and (max-width: 765px) {
   

    .form-inline {
      min-height: 80vh;
      max-height: 60vh;
      min-width: 100%;
      display: grid;
      grid-template-columns: repeat(1,auto);
      align-items: center;
      justify-content: center;
      opacity: 1;
    }

    .get-quote {
      min-width: 99vw;
    }
    .validate-input {
      padding: 5px;
    }
    .form-inline span {
      padding: 10px 70px;
      background-color: #c30000;
      color: white;
      cursor: pointer;
      border-radius: 10px;
    }

    .cancel {
      background-color: #c30000;
      display: block;
    }
   
  }

.services-container {
    height: 100vh;
    background-color: #1d1d1d;
}

.tab-component-container {
    margin-top: 80px;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Lato", sans-serif;
    background-color: #303956;
}

h1{
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    margin: 10%;
}
h3 {
    color: #fff;
}


.coming-soon {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.maintenance-title {
    margin-bottom: 2%;
}

.countdown {
    height: 20vh;
    display: flex;
    justify-content: space-around;
    background-color: #303956;
}

.day, .hour, .minute,.second{
    font-size: 3rem;
    color: #fff;
}

.waiting{
    height: 50vh;
}

.contact {
    font-size: 2rem;
    margin-top: 5%;
}


/* Different devices */



@media (max-width: 1399.98px) {  }


@media (max-width: 1199.98px) {  }


@media screen and (max-width: 960px) {
    .coming-soon {
        height: 80vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .maintenance-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

	h1{
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: 10%;
    }
}


@media(max-width: 767.98px) {
    .coming-soon {
        height: 80vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .maintenance-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 3%;
    }
    .waiting{
        height: 35vh;
    }

	h1{
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: 10%;
    }
}  

@media (max-width: 575.98px){

    .coming-soon {
        height: 80vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .maintenance-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 10%;
    }

    .waiting{
        height: 35vh;
    }

	h1{
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: 10%;
    }
}

* {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
}

body, html {
	height: 100%;
	font-family: "Lato", sans-serif;
}

/*---------------------------------------------*/
a {
	font-family: "Lato";
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
	color: #57b846;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-family: "Lato";
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder { color: #999999; }
input:-moz-placeholder { color: #999999; }
input::-moz-placeholder { color: #999999; }
input:-ms-input-placeholder { color: #999999; }

textarea::-webkit-input-placeholder { color: #999999; }
textarea:-moz-placeholder { color: #999999; }
textarea::-moz-placeholder { color: #999999; }
textarea:-ms-input-placeholder { color: #999999; }

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}




/*//////////////////////////////////////////////////////////////////
[ Contact 1 ]*/

.contact-form-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(213,34,40,1);
  cursor: default;
  text-transform: uppercase;
  margin-top: 5%;
  margin-bottom: 20px;
  
}


.contact-form-title h1{
  font: normal 900 2.5em 'Barlow';
}
.contact-title {
  display: flex;
  justify-content: center;
  margin: 3%;
  
}

.contact-form-row .row {
  width: 100%;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-form-row .row #left-side {
  flex: 100% 1;
  display: grid;
  place-items: center;
}
.contact-form-row .row #right-side {
  flex: 70% 1;
  display: grid;
  place-items: center;
}


.contact1 {
  width: 100%;
  min-height: 100%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vh;
}

.container-contact1 {
  width: 1163px;
  background: #1D1D1D;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 90px 130px 88px 148px;
}

/*------------------------------------------------------------------
[  ]*/
.contact1-pic {
  width: 450px;
}

.contact1-pic img {
  width: 100%;
  height: 100%;
}


/*------------------------------------------------------------------
[  ]*/
.contact1-form {
  width: 390px;
}

.contact1-form-title {
  display: block;
  font: normal 300 2em 'Barlow';
  color: #fff;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 44px;
}

input.input1 {
  height: 50px;
  border-radius: 15px;
  padding: 0 30px;
}
input.input1 + .shadow-input1 {
  border-radius: 25px;
}

textarea.input1 {
  min-height: 150px;
  border-radius: 15px;
  padding: 12px 80px;
}
textarea.input1 + .shadow-input1 {
  border-radius: 25px;
}

/*---------------------------------------------*/
.wrap-input1 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}

.input1 {
  display: block;
  width: 100%;
  background: #e6e6e6;
  font-family: "Lato";
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
}

.shadow-input1 {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: #303956;
}

.input1:focus + .shadow-input1 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

/*---------------------------------------------*/
.container-contact1-form-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact1-form-btn {
  min-width: 193px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid white;
  background: #D52228;
  font: normal 300 1.5em 'Barlow';
  line-height: 1.5;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;
}

.contact1-form-btn i {
  margin-left: 7px;
  transition: all 0.4s;
}

.contact1-form-btn:hover {
  background: #333333;
}

.contact1-form-btn:hover i {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}




/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 1200px) {
  .contact1-pic {
    width: 33.5%;
  }

  .contact1-form {
    width: 44%;
  }
}

@media (max-width: 992px) {
  .container-contact1 {
    padding: 90px 80px 88px 90px;
  }

  .contact1-pic {
    width: 35%;
  }

  .contact1-form {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .container-contact1 {
    padding: 90px 80px 88px 80px;
  }

  .contact1-pic {
    display: none;
  }

  .contact1-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container-contact1 {
    padding: 90px 15px 88px 15px;
  }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  font-family: "Lato";
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\F06A";
  font-family: "Roboto";
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
